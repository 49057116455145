// Generated by Framer (b2780b5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["MQw_O3jt2", "VEj70P40m"];

const serializationHash = "framer-KAY4k"

const variantClassNames = {MQw_O3jt2: "framer-v-1jdqm55", VEj70P40m: "framer-v-ydbpad"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "MQw_O3jt2", "Variant 2": "VEj70P40m"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "MQw_O3jt2", W41POyFF1: click ?? props.W41POyFF1} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, W41POyFF1, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "MQw_O3jt2", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaptg3y3p = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("VEj70P40m")
})

const onTap1xpcod = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("MQw_O3jt2")
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "VEj70P40m") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1jdqm55", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"MQw_O3jt2"} onTap={onTaptg3y3p} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({VEj70P40m: {"data-framer-name": "Variant 2", onTap: onTap1xpcod}}, baseVariant, gestureVariant)}><motion.div className={"framer-l9p9lr"} layoutDependency={layoutDependency} layoutId={"OpRrETC2g"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{VEj70P40m: {rotate: 45}}}/>{isDisplayed() && (<motion.div className={"framer-1a326iq"} layoutDependency={layoutDependency} layoutId={"JIkoCsc3w"} style={{backgroundColor: "rgb(255, 255, 255)"}}/>)}<motion.div className={"framer-1lggci5"} layoutDependency={layoutDependency} layoutId={"L8aJLwq6G"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{VEj70P40m: {rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KAY4k.framer-ncf7uw, .framer-KAY4k .framer-ncf7uw { display: block; }", ".framer-KAY4k.framer-1jdqm55 { cursor: pointer; height: 31px; overflow: hidden; position: relative; width: 31px; }", ".framer-KAY4k .framer-l9p9lr { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: 6px; width: 27px; }", ".framer-KAY4k .framer-1a326iq { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: calc(51.61290322580647% - 2px / 2); width: 27px; }", ".framer-KAY4k .framer-1lggci5 { bottom: 5px; flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; width: 27px; }", ".framer-KAY4k.framer-v-ydbpad.framer-1jdqm55 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 31px); }", ".framer-KAY4k.framer-v-ydbpad .framer-l9p9lr { top: calc(51.61290322580647% - 2px / 2); }", ".framer-KAY4k.framer-v-ydbpad .framer-1lggci5 { bottom: unset; top: calc(51.61290322580647% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"VEj70P40m":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"W41POyFF1":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqnYH1xmbg: React.ComponentType<Props> = withCSS(Component, css, "framer-KAY4k") as typeof Component;
export default FramerqnYH1xmbg;

FramerqnYH1xmbg.displayName = "hamburger";

FramerqnYH1xmbg.defaultProps = {height: 31, width: 31};

addPropertyControls(FramerqnYH1xmbg, {variant: {options: ["MQw_O3jt2", "VEj70P40m"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, W41POyFF1: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerqnYH1xmbg, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})